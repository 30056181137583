import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent {

    @ViewChild("top") top!: ElementRef;

}
