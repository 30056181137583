<ng-container *ngIf="postData$ | async as postData; else loader">
    <mat-card class="mt-3 min-h-30rem p-4 mat-elevation-z2 bg-white">
        <mat-card-title>
            <h3 class="my-2 display-4">{{ postData?.title }}</h3>
        </mat-card-title>
        <mat-card-subtitle class="my-4">
            <i class="fas fa-calendar" aria-hidden="true"></i>
            {{ postData?.createdDate | date: "longDate" }}
            <i class="fas fa-user ml-2" aria-hidden="true"></i>
            {{ postData?.author.name | titlecase }}
        </mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content class="py-3 news-content align-items-center align-content-center" [innerHTML]="safeHtml(postData?.content)">
        </mat-card-content>
    </mat-card>
</ng-container>
<ng-template #loader>
    <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>