import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ContactService } from 'src/app/services/contact.service';


@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

    formData!: FormGroup;
    displayReCapture: boolean = false;


    constructor(private builder: FormBuilder, private contact: ContactService, private messageService: MessageService) { }

    ngOnInit() {
        this.formData = this.builder.group({
            FullName: new FormControl('', [
                Validators.required]),
            ContactNumber: new FormControl('', [
                Validators.required]),
            Email: new FormControl('', [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            CompanyName: new FormControl('', [
                Validators.required]),
            Comment: new FormControl('', [
                Validators.required])
        })
    }

    resolved() {
        this.contact.postMessage(this.formData.value).subscribe(response => {
            this.messageService.add({ severity: 'success', summary: 'Contact', detail: `Thanks for your message we'll be in touch`, life: 3000 });
            this.formData.reset();
        }, error => {
            this.messageService.add({ severity: 'error', summary: 'Contact', detail: `There was an issue retrieving your message, please try again`, life: 3000 });
            this.displayReCapture = false;
        }, () => {
            this.displayReCapture = false;
        })
    }

    showReCapture() {
        this.displayReCapture = true;
    }

    get fullName() { return this.formData.get('FullName') }
    get contactNumber() { return this.formData.get('ContactNumber') }
    get email() { return this.formData.get('Email') }
    get companyName() { return this.formData.get('CompanyName') }
    get comment() { return this.formData.get('Comment') }


}
