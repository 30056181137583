import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from 'src/app/models/appuser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-admin-navigation',
    templateUrl: './admin-navigation.component.html',
    styleUrls: ['./admin-navigation.component.scss']
})
export class AdminNavigationComponent implements OnInit {
    opened: boolean = true;
    appUser: AppUser;

    constructor(private authService: AuthService,
        private router: Router) { }

    ngOnInit() {
        this.authService.appUser$.subscribe((appUser) => (this.appUser = appUser));
    }

    login() {
        this.router.navigate(['admin', 'auth', 'sign-in']);
    }

    logout() {
        this.authService.signOut();
    }
}
