import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    constructor(
        public authService: AuthService,
        private messageService: MessageService
    ) { }

    forgotPassword(passwordResetEmail: string) {
        this.authService.forgotPassword(passwordResetEmail)
            .then(() => {
                this.messageService.add({ severity: 'success', summary: 'Authentication', detail: 'Password reset email sent, check your inbox.', life: 3000 });
            })
            .catch((error) => {
                this.messageService.add({ severity: 'error', summary: 'Authentication', detail: error, life: 3000 });
            });
    }
}
