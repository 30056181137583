<div class="d-flex justify-content-between py-3">
  <div>
    <pagination-controls
      (pageChange)="pageChange($event)"
      class="my-pagination"
    ></pagination-controls>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Items per page: </mat-label>
      <mat-select
        [(ngModel)]="config.itemsPerPage"
        (selectionChange)="changePageItemCount($event)"
      >
        <mat-option *ngFor="let page of pageSizeOptions" [value]="page">
          {{ page }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
