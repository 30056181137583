import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ServiceInfo } from 'src/app/models/service-info';
import { CompanyServiceService } from 'src/app/services/company-service.service';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-company-service',
    templateUrl: './company-service.component.html',
    styleUrls: ['./company-service.component.scss']
})
export class CompanyServiceComponent {
    faBookOpen = faBookOpen;

    serviceInfo$: Observable<ServiceInfo>;
    currentArticleIndex: number = 0;

    constructor(private route: ActivatedRoute, private companyServiceService: CompanyServiceService) {
        const lastUrl = this.route.snapshot.url.pop();
        this.serviceInfo$ = this.companyServiceService.getCompanyService(lastUrl.path);
    }
}
