<ng-container *ngIf="serviceInfo$ | async as data; else loader">
    <mat-card class="mt-3 min-h-30rem p-4 mat-elevation-z2 bg-white">
        <mat-card-title>
            <h1 class="my-2 display-4">{{ contents.selectedOptions.hasValue() ?
                contents.selectedOptions.selected[0].value.title : 'none' }}</h1>
        </mat-card-title>
        <mat-divider></mat-divider>
        <img class="banner" [src]="data.banner">
        <mat-card-content class="col py-3 align-items-center align-content-center">
            <div class="grid">
                <div class="col-0 lg:col-3 "></div>
                <div class="col-12 lg:col-6" *ngIf="contents.selectedOptions.hasValue()">
                    <div [innerHTML]="contents.selectedOptions.selected[0].value.html"></div>
                </div>
                <div class="col-12 lg:col-3 contentList">
                    <mat-card *ngIf="data.brochure">
                        <a mat-raised-button color="primary" class="flex col py-1"
                            [href]="data.brochure.url" target="_blank">
                            <fa-icon [icon]="faBookOpen"></fa-icon> {{data.brochure.name}}
                        </a>
                    </mat-card>
                    <mat-divider *ngIf="data.brochure" class="flex my-3" ></mat-divider>
                    <mat-card>
                        <mat-selection-list #contents [multiple]="false">
                            <mat-list-option *ngFor="let content of data.content" [selected]="content.isActive"
                                [value]="content">
                                {{content.title}}
                            </mat-list-option>
                        </mat-selection-list>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

</ng-container>
<ng-template #loader>
    <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>