<div class="grid">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="New" class="p-button-success mr-2" [routerLink]="['/admin/news/edit']">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <button pButton pRipple label="Delete" class="p-button-danger" (click)="deleteSelectedPosts()"
                            [disabled]="!selectedPosts || !selectedPosts.length">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="posts" [columns]="cols" responsiveLayout="scroll" [rows]="rowsPerPageOptions[0]"
                [globalFilterFields]="['title','author.displayName', 'tags']" [paginator]="true" [rowsPerPageOptions]="rowsPerPageOptions"
                [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [(selection)]="selectedPosts" selectionMode="multiple" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Manage posts</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <!-- <th pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon>
                        </th> -->
                        <th pSortableColumn="title">Title <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <!-- <th>Image</th> -->
                        <th pSortableColumn="author">Author <p-sortIcon field="author.displayName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tags">Tags <p-sortIcon field="tags"></p-sortIcon>
                        </th>
                        <th>

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-post>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="post"></p-tableCheckbox>
                        </td>
                        <!-- <td style="width:14%; min-width:10rem;"><span class="p-column-title">Id</span>
                            {{post.code || post.id}}
                        </td> -->
                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">Title</span>
                            {{post.title}}
                        </td>
                        <!-- <td style="width:14%; min-width:10rem;"><span class="p-column-title">Image</span>
                            <img [src]="'assets/demo/images/post/' + post.image" [alt]="post.name" width="100" class="shadow-4" />
                        </td> -->
                        <td style="width:14%; min-width:8rem;">
                            <span class="p-column-title">Author</span>
                            {{post.author.displayName}}
                        </td>
                        <td style="width:60%; min-width:8rem;">
                            <span class="p-column-title">Tags</span>
                            <p-chips name="postTags" disabled="true" [(ngModel)]="post.tags" separator=","></p-chips>
                        </td>
                        <td>
                            <div class="flex gap-1">
                                <div class="flex align-items-center gap-1">
                                    <p-checkbox [(ngModel)]="post.published" [binary]="true" (click)="togglePublished(post)" inputId="published"></p-checkbox>
                                    <label for="published">Published</label>
                                </div>
                                <button pButton pRipple label="Edit" class="p-button-rounded p-button-primary mr-2"
                                    [routerLink]="['/admin/news/edit', post.id]">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button pButton pRipple label="Preview" class="p-button-rounded p-button-secondary mr-2"
                                    (click)="previewNews(post)">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>
                                <button pButton pRipple label="Delete" class="p-button-rounded p-button-warning" (click)="deletePost(post)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <p-dialog [(visible)]="deletePostDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="post">Are you sure you want to delete <b>{{post.name}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-text" label="No" (click)="deletePostDialog = false">
                    <mat-icon>cancel</mat-icon>
                </button>
                <button pButton pRipple class="p-button-text" label="Yes" (click)="confirmDelete()">
                    <mat-icon>check</mat-icon>
                </button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="deletePostsDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>Are you sure you want to delete selected posts?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-text" label="No" (click)="deletePostsDialog = false">
                    <mat-icon>cancel</mat-icon>
                </button>
                <button pButton pRipple class="p-button-text" label="Yes" (click)="confirmDeleteSelected()">
                    <mat-icon>check</mat-icon>
                </button>
            </ng-template>
        </p-dialog>
    </div>
</div>