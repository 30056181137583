import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-scroller',
    templateUrl: './scroller.component.html',
    styleUrls: ['./scroller.component.scss']
})
export class ScrollerComponent {

    @Input() href: string;
    showScroller: boolean;
    showScrollerPosition = 100;

    constructor(private router: Router) {}

    @HostListener('window:scroll')
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (scrollPosition >= this.showScrollerPosition) {
            this.showScroller = true;
        } else {
            this.showScroller = false;
        }
    }

    gotoTop() {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}
