import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {DialogModule} from 'primeng/dialog';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from "ng-recaptcha";



import { MatButtonModule } from '@angular/material/button';
import { environment } from 'src/environments/environment';
import { ContactFormComponent } from './contact-form.component';

@NgModule({
    declarations: [
        ContactFormComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        RecaptchaModule,
        DialogModule
    ],
    exports: [
        ContactFormComponent
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
        }
    ]
})
export class ContactFormModule { }
