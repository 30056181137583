import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-landing-navigation',
    templateUrl: './landing-navigation.component.html',
    styleUrls: ['./landing-navigation.component.scss']
})
export class LandingNavigationComponent implements OnInit {

    @ViewChild("top") top!: ElementRef;

    constructor() { }

    ngOnInit(): void {
    }

}
