import { Component, OnInit, OnDestroy } from "@angular/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Post } from "src/app/models/post";
import { DatePipe } from "@angular/common";
import { NewsService } from "src/app/services/news.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AppUser } from "src/app/models/appuser";
import { AuthService } from "src/app/services/auth.service";
import { Subject, throwError } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { CkeditorFirestoreService } from "src/app/services/ckeditor-firestore.service";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Timestamp } from "firebase/firestore";



@Component({
    selector: "app-news-editor",
    templateUrl: "./news-editor.component.html",
    styleUrls: ["./news-editor.component.scss"],
    providers: [DatePipe],
})
export class NewsEditorComponent implements OnInit, OnDestroy {
    public Editor = ClassicEditor;
    ckeConfig: any;
    postData = new Post();
    formTitle = "Add";
    postId: string;
    appUser: AppUser;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private blogService: NewsService,
        private router: Router,
        private authService: AuthService,
        private storage: AngularFireStorage,
    ) {
        this.route.paramMap.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((params: ParamMap) => {
            this.postId = params.get("id");
        });

        this.authService.appUser$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((appUser) => {
            this.appUser = appUser
        });
    }

    ngOnInit() {
        this.setEditorConfig();

        if (this.postId) {
            this.formTitle = "Edit";
            this.blogService
                .getPostbyId(this.postId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                    this.postData = result
                });
        }
    }

    saveBlogPost() {
        this.postData.author = {
            id: this.appUser.uid,
            displayName: this.appUser.name
        };
        if (this.postId && this.appUser) {
            this.blogService.updatePost(this.postId, this.postData).then(() => {
                this.router.navigate(['admin/news']);
            });
        } else if (!this.postId && this.appUser) {
            this.blogService.createPost(this.postData).then(() => {
                this.router.navigate(['admin/news']);
            });
        }
    }

    setEditorConfig() {
        this.ckeConfig = {
            removePlugins: [],
            extraPlugins: [CkeditorFirestoreService],
            // heading: {
            //     options: [
            //         {
            //             model: "paragraph",
            //             title: "Paragraph",
            //             class: "ck-heading_paragraph",
            //         },
            //         {
            //             model: "heading1",
            //             view: "h1",
            //             title: "Heading 1",
            //             class: "ck-heading_heading1",
            //         },
            //         {
            //             model: "heading2",
            //             view: "h2",
            //             title: "Heading 2",
            //             class: "ck-heading_heading2",
            //         },
            //         {
            //             model: "heading3",
            //             view: "h3",
            //             title: "Heading 3",
            //             class: "ck-heading_heading3",
            //         },
            //         {
            //             model: "heading4",
            //             view: "h4",
            //             title: "Heading 4",
            //             class: "ck-heading_heading4",
            //         },
            //         {
            //             model: "heading5",
            //             view: "h5",
            //             title: "Heading 5",
            //             class: "ck-heading_heading5",
            //         },
            //         {
            //             model: "heading6",
            //             view: "h6",
            //             title: "Heading 6",
            //             class: "ck-heading_heading6",
            //         },
            //         { model: "Formatted", view: "pre", title: "Formatted" },
            //     ],
            // },
        };
    }

    onReady(editor: ClassicEditor): void {
        const fileRepository = editor.plugins.get('FileRepository');
        fileRepository.createUploadAdapter = (loader) => {
            return new CkeditorFirestoreService(loader, this.storage);
        };
    }

    cancel() {
        this.router.navigate(['admin/news']);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    myUploader(event) {
        const file = event.files[0];
        const filePath = `news-images/${file.name}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);

        // get notified when the download URL is available
        const snapShotChanges$ = task.snapshotChanges().pipe(
            catchError((error, caught) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                // eslint-disable-next-line default-case
                switch (error.code) {
                    case "storage/unauthorized":
                        throwError(" User doesn't have permission to access the object");
                        break;

                    case "storage/canceled":
                        throwError("User canceled the upload");
                        break;

                    case "storage/unknown":
                        throwError(
                            "Unknown error occurred, inspect error.serverResponse"
                        );
                        break;
                }
                return caught
            }),
            map(t => t.task.snapshot.state)
        ).subscribe(state => {
            if (state === 'success') {
                fileRef.getDownloadURL().subscribe(
                    uri => {
                        this.postData.headerImageUrl = uri;
                        snapShotChanges$.unsubscribe();
                    }
                )
            }
        })
    }

    removeHeaderImage() {
        this.postData.headerImageUrl = null;
    }
}
