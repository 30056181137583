import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin, from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SlugPipe } from 'src/app/customPipes/slug.pipe';
import { Post } from 'src/app/models/post';
import { NewsService } from 'src/app/services/news.service';

@Component({
    templateUrl: './app.crud.component.html',
    styleUrls: ['./app.crud.component.scss'],
    providers: [
        SlugPipe
    ]
})
export class AppCrudComponent implements OnInit {

    postDialog: boolean;

    deletePostDialog: boolean = false;

    deletePostsDialog: boolean = false;

    posts: Post[];

    post: Post;

    selectedPosts: Post[];

    submitted: boolean;

    cols: any[];

    rowsPerPageOptions = [10, 20, 30];

    constructor(private newsService: NewsService, private messageService: MessageService, private router: Router, private slugPipe: SlugPipe) { }

    ngOnInit() {
        this.newsService.getAllPosts().pipe(first()).subscribe(data => this.posts = data);

        this.cols = [
            { field: 'title', header: 'Title' },
            { field: 'author', header: 'Author' },
            { field: 'id', header: 'id' }
        ];
    }

    openNew() {
        this.post = {} as Post;
        this.submitted = false;
        this.postDialog = true;
    }

    deleteSelectedPosts() {
        this.deletePostsDialog = true;
    }

    togglePublished(post: Post) {
        this.newsService.updatePost(post.id, post)
    }

    editPost(product: Post) {
        this.post = { ...product };
        this.postDialog = true;
    }

    deletePost(product: Post) {
        this.deletePostDialog = true;
        this.post = { ...product };
    }

    confirmDeleteSelected() {
        this.deletePostsDialog = false;

        const postCountToDelete = this.selectedPosts.length;
        let deletes: Observable<Post>[] = [];
        this.selectedPosts.forEach((post) => deletes.push(from(this.newsService.deletePost(post.id)).pipe(
            map(_ => post)
        )));

        forkJoin([...deletes]).subscribe(deletedPosts => {
            if (deletedPosts.length === postCountToDelete) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Posts Deleted', life: 3000 });
            }
        });
    }

    confirmDelete() {
        this.deletePostDialog = false;
        this.posts = this.posts.filter(val => val.id !== this.post.id);
        this.newsService.deletePost(this.post.id).then(() => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Post Deleted', life: 3000 });
            this.post = {} as Post;
        });
    }

    hideDialog() {
        this.postDialog = false;
        this.submitted = false;
    }

    findIndexById(id: string): number {
        let index = -1;
        for (let i = 0; i < this.posts.length; i++) {
            if (this.posts[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    createId(): string {
        let id = '';
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    previewNews(post: Post) {
        // Converts the route into a string that can be used 
        // with the window.open() function
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/news/${post.id}/${this.slugPipe.transform(post.title)}`])
        );

        window.open(url, '_blank');
    }
}
