<h1 class="mt-2">{{ formTitle }} Post</h1>
<hr />
<mat-card class="mat-elevation-z2 mt-3">
    <form #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveBlogPost()" accept-charset="UTF-8" novalidate>
        <div class="flex flex-row flex-wrap gap-4">
            <div class="flex flex-column gap-1">
                <div>
                    <h4>Title</h4>
                    <div *ngIf="(postTitle.invalid && postTitle.touched) || postTitle.dirty">
                        <small *ngIf="postTitle.errors?.required" class="text-danger">Title is required</small>
                    </div>
                    <input pInputText type="text" class="blogHeader" placeholder="Add title..." class="form-control"
                        name="postTitle" [(ngModel)]="postData.title" #postTitle="ngModel" required />
                </div>
                <div>
                    <h4>Header Image</h4>
                    <p-fileUpload *ngIf="!this.postData.headerImageUrl" #fubauto mode="basic" name="demo[]" accept="image/*"
                        maxFileSize="2500000" [customUpload]="true" (uploadHandler)="myUploader($event)" [auto]="true"
                        chooseLabel="Browse">
                    </p-fileUpload>
                    <div *ngIf="this.postData.headerImageUrl" style="aspect-ratio: 16/9; max-width: 600px; width: auto; height: auto;"
                        class="container">
                        <img [src]="this.postData.headerImageUrl" style="aspect-ratio: 16/9; width: 600px; height: auto;">
                        <button pButton pRipple label="Delete" class="p-button-danger btn"
                            (click)="removeHeaderImage()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-column gap-1">
                <div>
                    <h4>Tags</h4>
                    <div *ngIf="(postTitle.invalid && postTitle.touched) || postTitle.dirty">
                        <small *ngIf="postTitle.errors?.required" class="text-danger">Title is required</small>
                    </div>
                    <p-chips name="postTags" [(ngModel)]="postData.tags" separator="," placeholder="Hint: a, b, c"></p-chips>
                </div>
                <div>
                    <div class="flex align-items-center gap-1">
                        <p-checkbox name="published" [(ngModel)]="postData.published" [binary]="true" inputId="published"></p-checkbox>
                        <label for="published">Published</label>
                    </div>
                </div>
            </div>
        </div>

        <br />
        <div class="form-group">
            <ckeditor name="myckeditor" [config]="ckeConfig" [(ngModel)]="postData.content" #myckeditor="ngModel"
                debounce="300" [editor]="Editor" (ready)="onReady($event)"></ckeditor>
        </div>
        <mat-card-actions>
            <div fxflex></div>
            <button type="submit" mat-raised-button color="primary">Save</button>
            <button mat-raised-button color="warn" (click)="cancel()">CANCEL</button>
        </mat-card-actions>
    </form>
</mat-card>