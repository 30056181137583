import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AppUser } from "../models/appuser";
import firebase from "firebase/compat/app";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    appUser$: Observable<AppUser>;

    constructor(
        public afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        private router: Router,
        private afs: AngularFirestore
    ) {
        // Get the auth state, then fetch the Firestore user document or return null
        this.appUser$ = this.afAuth.authState.pipe(
            switchMap((user) => {
                // If the user is logged in, return the user details.
                if (user) {
                    return this.afs.doc<AppUser>(`appusers/${user.uid}`).valueChanges();
                } else {
                    // If the user is NOT logged in, return null.
                    return of(null);
                }
            })
        );
    }

    // Sign in with email/password
    async signIn(email: string, password: string) {
        try {
            const result = await this.afAuth.signInWithEmailAndPassword(email, password)
                .then()
                .catch();
            this.setUserData(result.user);
            this.afAuth.authState.subscribe((user_1) => {
                if (user_1) {
                    const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? 'admin/news';
                    this.router.navigate([returnUrl]);
                }
            });
        } catch (error) {
            // return error;
        }
    }

    // Sign up with email/password
    async signUp(email: string, password: string) {
        try {
            const result = await this.afAuth
                .createUserWithEmailAndPassword(email, password);
            /* Call the SendVerificaitonMail() function when new user sign
            up and returns promise */
            this.sendVerificationMail();
            this.setUserData(result.user);
        } catch (error) {
            // this.messageService.add({ severity: 'error', summary: 'Authentication', detail: error.message, life: 3000 });
        }
    }

    // Send email verfificaiton when new user sign up
    async sendVerificationMail() {
        return this.afAuth.currentUser
            .then((u: firebase.User) => u.sendEmailVerification())
            .then(() => {
                this.router.navigate(['auth', 'verify-email-address']);
            });
    }

    // Reset Forggot password
    async forgotPassword(passwordResetEmail: string) {
        return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
    }

    // Returns true when user is logged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null && user.emailVerified !== false ? true : false;
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    setUserData(user: any) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `appusers/${user.uid}`
        );

        const userData: AppUser = {
            uid: user.uid,
            email: user.email,
            name: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        } as AppUser;
        return userRef.set(userData, {
            merge: true,
        });
    }

    // Sign out
    async signOut() {
        await this.afAuth.signOut();
        localStorage.removeItem('user');
        this.router.navigate(['admin', 'auth', 'sign-in']);
    }
}
