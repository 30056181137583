<mat-sidenav-container>
    <mat-sidenav id="drawer" mode="over" position="end" #drawer [fixedInViewport]="true" class="example-sidenav">
        <ul class="landing-menu-side">
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('cosmetic')">COSMETIC</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('dental')">DENTAL</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('medical')">MEDICAL</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('industrial')">INDUSTRIAL</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('meetEsschemEurope')">MEET ESSCHEM
                    EUROPE</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="scroll('affiliates')">AFFILIATES</a>
            </li>
            <li class="landingMenuItem" #landingMenuItem>
                <a (click)="contact('contact')">CONTACT</a>
            </li>
        </ul>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="landing-body">
            <div class="landing-wrapper">
                <div id="home" class="landing-header">
                    <div class="landing-topbar">
                        <a href="#home" class="logo">
                            <img src="../../assets/layout/images/pages/landing/logo.png" alt="logo" />
                        </a>
                        <ul class="landing-menu">
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('cosmetic')">COSMETIC</a>
                            </li>
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('dental')">DENTAL</a>
                            </li>
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('medical')">MEDICAL</a>
                            </li>
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('industrial')">INDUSTRIAL</a>
                            </li>
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('meetEsschemEurope')">MEET ESSCHEM EUROPE</a>
                            </li>
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="scroll('affiliates')">AFFILIATES</a>
                            </li>
                        </ul>

                        <div (click)="drawer.toggle()" id="landing-menu-button" tabindex="0">
                            <i class="pi pi-bars"></i>
                        </div>
                        <ul class="contact-menu">
                            <li class="landingMenuItem" #landingMenuItem>
                                <a (click)="contact('contact')">CONTACT</a>
                            </li>
                        </ul>
                    </div>
                    <ng-content select="[head]"></ng-content>
                </div>
                <ng-content select="[body]"></ng-content>
                <div id="contact" class="landing-contact">
                    <div class="section-title">
                        <h1>Get In Touch</h1>
                    </div>
                    <div class="contact-wrapper">
                        <app-contact-form class="contact-left flex-column"></app-contact-form>
                        <div class="contact-right">
                            <div class="contact-text">
                                <h1>Feel free to contact us and we will get <br />
                                    back to you <br />
                                    as soon as we can.</h1>
                                <div class="contact-info">
                                    <div class="info">
                                        <h2>Address</h2>
                                        <p>Esschem Europe Ltd<br />
                                            Unit 3 Hall Dene Way<br />
                                            Seaham Grange Industrial Estate<br />
                                            Seaham<br />
                                            SR7 0PU
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
                    <div class="landing-footer">
                        <a (click)="returnHome()" class="logo">
                            <img src="../../assets/layout/images/pages/landing/logo.png" alt="logo" />
                        </a>
                        <div class="accreds">
                            <img src="../../assets/layout/images/pages/landing/LRQA-ISO9001.jpg" alt="accreds" />
                            <img src="../../assets/layout/images/pages/landing/LRQA-ISO22716.jpg" alt="accreds" />
                        </div>
                    </div>
        
                    <div class="landing-mask"></div>
                </div>
            </div>
        </div>
        <app-scroller [href]="home"></app-scroller>
    </mat-sidenav-content>
</mat-sidenav-container>