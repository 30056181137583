import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgMaterialModule } from "./ng-material/ng-material.module";
import { NgxPaginationModule } from "ngx-pagination";
import { NewsComponent } from "./components/blog/blog.component";
import { HomeComponent } from "./components/home/home.component";
import { ScrollerComponent } from "./components/scroller/scroller.component";
import { NewsEditorComponent } from "./components/news-editor/news-editor.component";
import { NewsCardComponent } from "./components/blog-card/blog-card.component";
import { ExcerptPipe } from "./customPipes/excerpt.pipe";
import { SlugPipe } from "./customPipes/slug.pipe";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { AuthorProfileComponent } from "./components/author-profile/author-profile.component";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { ShareButtonsConfig, ShareModule } from "ngx-sharebuttons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireModule } from "@angular/fire/compat";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { ContactFormModule } from "./components/contact-form/contact-form.module";
import { MatSidenavModule } from '@angular/material/sidenav'
import { AppRoutingModule } from "./app-routing.module";
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { LandingNavigationComponent } from './components/landing-navigation/landing-navigation.component';
import { AdminNavigationComponent } from './components/admin-navigation/admin-navigation.component';
import { BreadcrumbModule } from "xng-breadcrumb";
import { FrameworkComponent } from './components/framework/framework.component';


// PrimeNg
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { AppCrudComponent } from "./components/news-crud/app.crud.component";
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { SignInComponent } from './components/authentication/sign-in/sign-in.component';
import { SignUpComponent } from './components/authentication/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/authentication/verify-email/verify-email.component';
import { MessageService, ConfirmationService } from "primeng/api";
import { CompanyServiceComponent } from './pages/company-service/company-service.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';


const customConfig: ShareButtonsConfig = {
    autoSetMeta: true,
    twitterAccount: "test"
};

@NgModule({
    declarations: [
        AppComponent,
        NewsComponent,
        HomeComponent,
        ScrollerComponent,
        NewsEditorComponent,
        NewsCardComponent,
        ExcerptPipe,
        SlugPipe,
        PaginatorComponent,
        AuthorProfileComponent,
        LandingPageComponent,
        NewsFeedComponent,
        LandingNavigationComponent,
        AdminNavigationComponent,
        FrameworkComponent,
        AppCrudComponent,
        SignInComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        VerifyEmailComponent,
        CompanyServiceComponent
    ],
    providers: [
        ScreenTrackingService,
        MessageService,
        ConfirmationService
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        ShareIconsModule,
        NgxPaginationModule,
        HttpClientModule,
        FontAwesomeModule,
        ShareModule.withConfig(customConfig),
        BrowserModule,
        BrowserAnimationsModule,
        NgMaterialModule,
        CKEditorModule,
        FormsModule,
        MatSidenavModule,
        ContactFormModule,
        AppRoutingModule,
        BreadcrumbModule,
        FileUploadModule,

        // PrimeNg
        MessagesModule,
        MessageModule,
        ToastModule,
        DialogModule,
        ToolbarModule,
        TableModule,
        InputTextModule,
        ImageModule,
        ChipsModule,
        CheckboxModule
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
