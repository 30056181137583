import { Component, OnInit } from '@angular/core';
import { faCertificate, faExpandAlt, faHandshake, faHeadset, faBookOpen } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    faBookOpen = faBookOpen;
    faCertificate = faCertificate;
    faExpandAlt = faExpandAlt;
    faHandshake = faHandshake;
    faHeadset = faHeadset;

    dentalBrochure = "https://www.paperturn-view.com/esschem-europe/esschem-brochure-2022?pid=MjQ242824";
    cosmeticBrochure = "https://www.paperturn-view.com/esschem-europe/cosmetic-brochure-november-2022?pid=Mjg284618&fbclid=IwAR0me_uazHCTCdQsJhVN-6gWxcIJUGcMFwHl2vKeCcZZ2HUyO4sDXmlMiRE";

    constructor() { }

    ngOnInit(): void {
        this.reveal();
        window.addEventListener("scroll", this.reveal);
    }

    reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else if (elementTop > windowHeight) {
                reveals[i].classList.remove("active");
            }
        }
    }
}
