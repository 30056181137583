import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-framework',
    templateUrl: './framework.component.html',
    styleUrls: ['./framework.component.scss'],
})
export class FrameworkComponent implements OnInit {
    @ViewChild("drawer") drawer!: any;
    @ViewChild("home") home!: ElementRef;

    constructor(private scroller: ViewportScroller, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
    }

    scroll(target: string) {
        this.router.navigate(['']).then(() => {
            this.router.navigate([], {
                fragment: target
            })
            // this.scroller.scrollToAnchor(target);
            this.drawer.close();
        });
    }

    contact(target: string) {
        this.router.navigate([], {
            fragment: ''
        }).then(() => {
            this.router.navigate([], {
                fragment: target
            })
        })


        this.drawer.close();
    }

    returnHome() {
        this.router.navigate([''], {
            fragment: null
        })
    }
}
