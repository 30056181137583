// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    projectId: 'esschem-europe',
    appId: '1:657218799130:web:667ae590ceac550cdff3a3',
    storageBucket: 'esschem-europe.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyC-lzc3FKMisNZpS03nCshr5UCiPfmEVzU',
    authDomain: 'esschem-europe.firebaseapp.com',
    messagingSenderId: '657218799130',
    measurementId: 'G-WMKK1V16K7',
  },
  recaptcha: {
    siteKey: '6LcoZygjAAAAAP8nM_spTXSeMXXIrz-KT3JzYyhY',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
