import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {

    constructor(
        public authService: AuthService, private messageService: MessageService
    ) { }

    signIn(userName: string, userPassword: string) {
        try {
            this.authService.signIn(userName, userPassword)
        }
        catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Authentication', detail: error.message, life: 3000 });
        }
    }
}
