import { AngularFireStorage } from '@angular/fire/compat/storage';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

export class CkeditorFirestoreService {
    uploadPercent: Observable<number>;
    downloadURL$: Subject<{ default: string }> = new Subject<{ default: string }>;


    constructor(private loader: { file: Promise<any>; }, private storage: AngularFireStorage) {
    }

    // Starts the upload process.
    upload() {
        return from(this.loader.file).pipe(
            mergeMap(file => {
                const filePath = `news-images/${file.name}`;
                const fileRef = this.storage.ref(filePath);
                const task = this.storage.upload(filePath, file);

                // get notified when the download URL is available
                const snapShotChanges$ = task.snapshotChanges().pipe(
                    catchError((error, caught) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        // eslint-disable-next-line default-case
                        switch (error.code) {
                            case "storage/unauthorized":
                                throwError(" User doesn't have permission to access the object");
                                break;

                            case "storage/canceled":
                                throwError("User canceled the upload");
                                break;

                            case "storage/unknown":
                                throwError(
                                    "Unknown error occurred, inspect error.serverResponse"
                                );
                                break;
                        }
                        return caught
                    }),
                    map(t => t.task.snapshot.state)
                ).subscribe(state => {
                    if (state === 'success') {
                        fileRef.getDownloadURL().subscribe(
                            uri => {
                                this.downloadURL$.next({ default: uri })
                                this.downloadURL$.complete();
                                snapShotChanges$.unsubscribe();
                            }
                        )
                    }
                })

                return this.downloadURL$;
            })
        ).toPromise();
    }
}