<app-framework>
    <ng-container body>
        <div class="landing-content">
            <xng-breadcrumb>
                <ng-container *xngBreadcrumbItem="let breadcrumb">
                    <span class="text-white text-lg"> {{ breadcrumb | titlecase }} </span>
                </ng-container>
            </xng-breadcrumb>
            <router-outlet></router-outlet>
        </div>
    </ng-container>
</app-framework>