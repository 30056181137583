import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Post } from "src/app/models/post";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NewsService } from "src/app/services/news.service";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { BreadcrumbService } from "xng-breadcrumb";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "app-blog",
    templateUrl: "./blog.component.html",
    styleUrls: ["./blog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NewsComponent implements OnDestroy {
    postData$: Observable<Post>;
    postId;
    private unsubscribe$ = new Subject<void>();
    
    constructor(private route: ActivatedRoute, private blogService: NewsService, private breadcrumbService: BreadcrumbService, private sanitizer: DomSanitizer) {
        this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe((params: ParamMap) => {
            this.postId = params.get("id");
            this.postData$ = this.blogService.getPostbyId(this.postId).pipe(
                map(post => {
                    breadcrumbService.set('@slugName', { skip: false, label: post.title} );
                    return post;
                })
            );
        });
    }

    safeHtml(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
