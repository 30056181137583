import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Post } from "../models/post";
import { Timestamp } from "firebase/firestore";

@Injectable({
    providedIn: "root",
})
export class NewsService {
    constructor(private db: AngularFirestore) { }

    createPost(post: Post) {
        return this.db.collection<Post>("blogs").add({
            ...post, createdDate: post.createdDate = Timestamp.now()
        });
    }

    getAllPosts(): Observable<Post[]> {
        const blogs = this.db
            .collection<Post>("blogs", (ref) => ref.orderBy("createdDate", "desc"))
            .valueChanges({idField: 'id'})
            .pipe(
                map((actions) => {
                    return actions.map((c) => ({
                        ...c,
                        createdDate: new Date(c.createdDate.seconds * 1000)
                    }));
                })
            );
        return blogs;
    }

    getPostbyId(id: string): Observable<Post> {
        const blogDetails = this.db.doc<Post>("blogs/" + id).valueChanges().pipe(
            map(post => {
                return {
                    ...post,
                    createdDate: new Date(post.createdDate.seconds * 1000)
                }
            })
        );
        return blogDetails;
    }

    updatePost(postId: string, post: Post) {
        const putData = JSON.parse(JSON.stringify(post));
        putData.createdDate = Timestamp.fromDate(post.createdDate);
        return this.db.doc("blogs/" + postId).update(putData)
    }

    deletePost(postId: string) {
        return this.db.doc("blogs/" + postId).delete();
    }
}

