<ng-container *ngIf="newsPost$ | async as newsPost; else loading">
    <ng-container *ngIf="newsPost?.length > 0; else noData">
        <div class="flex {{flexDirection()}} justify-content-center align-items-center gap-4 my-4 flex-wrap">
            <ng-container *ngFor="let post of newsPost | paginate: config">
                <mat-card *ngIf="post.published"
                    class="flex flex-column w-12 md:w-26rem mat-elevation-z2 justify-content-center cursor-pointer">
                    <img [routerLink]="['/news/', post.id, post.title | slug]" class="newsfeed-image" alt="newsfeed-image"
                        [src]="post.headerImageUrl" data-src="../../assets/layout/images/pages/landing/Gels home pic.jpg"
                        data-srcset="../../assets/layout/images/pages/landing/Gels home pic.jpg" />
                    <div [routerLink]="['/news/', post.id, post.title | slug]">
                        <mat-card-content>
                            <a class="blog-title">
                                <h2>{{ post.title }}</h2>
                            </a>
                        </mat-card-content>
                        <mat-divider></mat-divider>

                        <div class="flex flex-row justify-content-between">
                            <span>{{ post.createdDate | date:'d MMMM, y' }}</span>
                        </div>
                    </div>
                </mat-card>
            </ng-container>

        </div>
        <ng-container *ngIf="showPagination && newsPost?.length > pageSize">
            <mat-card>
                <app-paginator [pageSizeOptions]="pageSizeOptions" [config]="config"></app-paginator>
            </mat-card>
        </ng-container>
    </ng-container>
    <ng-template #noData>
        <h3>No data found</h3>
    </ng-template>
</ng-container>

<ng-template #loading>
    <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>