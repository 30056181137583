<mat-card class="mb-3 mat-elevation-z2">
  <mat-card-content>
    <div class="text-center">
      <img
        class="text-center"
        mat-card-avatar
        src="../../../assets/ankit-sharma.jpg"
      />
      <h5>Ankit Sharma</h5>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-content class="d-flex justify-content-center py-2">
    <a href="https://www.facebook.com/Ankit.Sharma.0709" target="_blank"
      ><i class="fab fa-facebook-square"></i
    ></a>
    <a href="https://twitter.com/ankitsharma_007" target="_blank"
      ><i class="fab fa-twitter-square" aria-hidden="true"></i
    ></a>
    <a href="https://www.linkedin.com/in/ankitsharma-007/" target="_blank"
      ><i class="fab fa-linkedin" aria-hidden="true"></i
    ></a>
    <a href="https://github.com/AnkitSharma-007" target="_blank"
      ><i class="fab fa-github-square" aria-hidden="true"></i
    ></a>
  </mat-card-content>
</mat-card>
