<form [formGroup]="formData">
    <div class="card-contact flex flex-column">
        <h3>Say hi to the team</h3>

        <div *ngIf="(fullName.invalid && fullName.touched) || fullName.dirty">
            <small *ngIf="fullName.errors?.required" class="text-danger">Full Name is required</small>
        </div>
        <input placeholder="Full Name" type="text" class="form-control" name="FullName" formControlName="FullName">

        <div *ngIf="(contactNumber.invalid && contactNumber.touched) || contactNumber.dirty">
            <small *ngIf="contactNumber.errors?.required" class="text-danger">Contact Number is required</small>
        </div>
        <input placeholder="Contact Number" type="number" class="form-control" name="ContactNumber"
            formControlName="ContactNumber">

        <div *ngIf="(email.invalid && email.touched) || email.dirty">
            <small *ngIf="email.errors?.required" class="text-danger">Email is required</small>
            <small *ngIf="email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
        </div>
        <input placeholder="Email" type="email" name="email" email type="email" class="form-control" name="Email"
            aria-describedby="emailHelp" formControlName="Email">

        <div *ngIf="(companyName.invalid && companyName.touched) || companyName.dirty">
            <small *ngIf="companyName.errors?.required" class="text-danger">Company Name is required</small>
        </div>
        <input placeholder="Company Name" type="text" class="form-control" name="CompanyName"
            formControlName="CompanyName">

        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        <div *ngIf="(comment.invalid && comment.touched) || comment.dirty">
            <small *ngIf="comment.errors?.required" class="text-danger">Comment required</small>
        </div>
        <textarea placeholder="Your Message" class="form-control" formControlName="Comment" name="Comment" rows="8"
            autoResize="false"></textarea>
        <button mat-raised-button color="primary" (click)="showReCapture()" icon="pi pi-info-circle" label="Show"
            [disabled]="!formData.valid">Send</button>
        <p-dialog [modal]="true" [(visible)]="displayReCapture" dismissableMask="true" [draggable]="false"
            [showHeader]="false">
            <ng-container *ngIf="displayReCapture">
                <re-captcha (resolved)="resolved()"></re-captcha>
            </ng-container>
        </p-dialog>
    </div>
</form>