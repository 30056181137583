import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Post } from 'src/app/models/post';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
    selector: 'app-news-feed',
    templateUrl: './news-feed.component.html',
    styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {
    @Input() pageSize: number = null;
    @Input() showPagination: boolean = false;
    @Input() admin: boolean = true;
    @Input() row: boolean = true; 

    config: any;
    newsPost$: Observable<Post[]>;
    appUser$ = this.authService.appUser$;

    flexDirection = () => { return this.row ? 'flex-row' : 'flex-column' }

    private unsubscribe$ = new Subject<void>();

    constructor(
        private blogService: NewsService,
        private route: ActivatedRoute,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.config = {
            currentPage: 1,
            itemsPerPage: this.pageSize,
        };
        this.route.paramMap
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((params: ParamMap) => {
                this.config.currentPage = params.get("pagenum");
                this.newsPost$ = this.blogService.getAllPosts();
            });
    }

    delete(postId: string) {
        if (confirm("Are you sure?")) {
          this.blogService.deletePost(postId).then(() => {
            //this.snackBarService.showSnackBar("Blog post deleted successfully");
          });
        }
      }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
