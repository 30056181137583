export class Post {
    id: string;
    title: string;
    content: string;
    author: {
        id: string;
        displayName: string;
    };
    tags: string[];
    createdDate: any;
    headerImageUrl: string;
    published: boolean;

    constructor() {
        this.title = '';
        this.content = '';
    }
}
